import * as React from 'react';
import { Link } from 'gatsby';
import { Box, Container, Flex, SimpleGrid, Stack } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import SEO from '../../components/seo';
import Layout from '../../components/layout';
import CustomButton from '../../components/custom-button';
import '../../styles/product/crystal-green.scss';
// import PlayIcon from '../../images/play-icon.svg';

const CrystalGreen = () => {
  // const config = {
  //   config: {
  //     file: {
  //       attributes: {
  //         controlsList: 'nodownload',
  //       },
  //     },
  //   },
  //   playIcon: <img src={PlayIcon} alt="playicon" />,
  //   url: 'https://www.youtube.com/watch?v=jD_Kid4EUAA',
  //   light:
  //     'https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/ostara-crystal-green-video.jpeg',
  //   playing: true,
  //   controls: true,
  //   width: '100%',
  // };

  return (
    <Layout>
      <SEO
        title="Crystal Green Fertilizer | Crop Driven Release™ Fertilizer | Ostara"
        description="Discover Crystal Green's enhanced-efficiency, Crop Driven Release™ fertilizer providing P, N and Mg when and where roots need them most for higher yield and greater ROI."
        image="https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/ostara_logo.png"
        keywords="Crystal Green Fertilizer"
      />
      <main>
        <Box w="100%" p={4} className="crystal-green-hero-container">
          <Container maxW={1400}>
            <Box
              display="flex"
              flexDirection={[
                'column-reverse',
                'column-reverse',
                'column-reverse',
                'row',
              ]}
              justifyContent="space-between"
              paddingTop={[0, 0, 10, 50]}
            >
              <Box
                marginTop={[12, 12, 12, 0]}
                w={['100%', '100%', '100%', '46.5%']}
              >
                <StaticImage
                  className="crystal-green-hero-img"
                  placeholder="transparent"
                  src="../../images/crystal-green-min.jpg"
                  alt="Ostara logo"
                />
              </Box>
              <Stack
                spacing={[4, 6, 4, 6, 8]}
                w={['100%', '100%', '100%', '46.5%']}
              >
                <h1>
                  Crystal Green<sup className="sup-character">®</sup> Fertilizer
                </h1>
                <p>
                  Enhanced efficiency, Crop Driven Release™ fertilizer that puts
                  your crops in control of nutrient uptake with reduced runoff
                  and soil fixation.
                </p>
                <Box className="button-wrappers">
                  <CustomButton
                    variant="crystal"
                    maxW={250}
                    to="/contact"
                    className="button-crystal"
                  >
                    CONTACT US TO ORDER
                  </CustomButton>
                </Box>
              </Stack>
            </Box>

            <Box
              display={['block', 'block', 'block', 'flex']}
              justifyContent="space-between"
            >
              <Flex
                w={['100%', '100%', '100%', '46.5%']}
                marginTop={10}
                direction="column"
              >
                <StaticImage
                  className="crystal-green-hero-img"
                  placeholder="transparent"
                  src="../../images/crystal-green-field.jpg"
                  alt="Ostara logo"
                />
                <StaticImage
                  className="crystal-green-hero-img"
                  placeholder="transparent"
                  src="../../images/ostara-days-study-min.jpg"
                  alt="Ostara logo"
                />
              </Flex>
              <Stack
                className="crystal-green-box"
                marginTop={10}
                w={['100%', '100%', '100%', '46.5%']}
                spacing={6}
              >
                <h5>About Crystal Green Fertilizer</h5>
                <p>
                  Crystal Green fertilizer uses Crop Driven Release™ to provide
                  phosphate, along with nitrogen and magnesium (5-28-0 with 10%
                  Mg), when roots need it, benefitting crops with efficient
                  phosphate control and uptake for higher yields. Crystal Green
                  is available in SGN 300.
                </p>
                <h5>Key Nutrients</h5>
                <StaticImage
                  placeholder="transparent"
                  src="../../images/key-nutrients-micrographic.png"
                  alt="Ostara logo"
                  className="ingredients-graph"
                />
                <p>DERIVATION</p>
                <div className="crystal-green-derivation">
                  <h3>MgNH₄PO₄ - 6H₂O</h3>
                  <h4>MAGNESIUM AMMONIUM PHOSPHATE HEXAHYDRATE</h4>
                </div>
              </Stack>
            </Box>

            <SimpleGrid
              marginTop={[16, 40]}
              marginBottom={[16, 0]}
              columns={{ md: 1, lg: 2 }}
              spacing={20}
              className="product-player-container"
            >
              <Box w="100%">
                <h4>HOW CRYSTAL GREEN WORKS</h4>
                <h1>Gets to the Root of Nutrient Uptake</h1>
                <p>
                  Crystal Green Crop Driven Release™ fertilizer releases
                  phosphate, plus nitrogen and magnesium, in response to organic
                  acids emitted by roots. With ongoing access to phosphate
                  according to crop demand, Crystal Green safely supplies the
                  roots with nutrients needed for optimal plant growth.
                </p>
              </Box>
              <Box w="100%">
                <StaticImage
                  className="crystal-green-trilogy-graphic"
                  placeholder="transparent"
                  src="../../images/Ostara Roots_Acids_CG_Zoom.png"
                  alt="Ostara logo"
                />
              </Box>
            </SimpleGrid>
          </Container>
        </Box>

        <Box w="100%" p={4} className="product-body-hero-container">
          <div className="overlay" />
          <Container maxW={1400}>
            <Stack marginTop={[0, 100]} maxW="35rem" spacing={6}>
              <h4>BENEFITS</h4>
              <h2>Produce More, Waste Less</h2>
              <p>
                Crystal Green’s Crop-Driven Release™ optimizes nutrient uptake
                and reduces soil tie-up and runoff for maximum efficiency. With
                this patented process, Crystal Green helps protect local water
                resources while increasing yields. Farmers finally have
                optimized nutrition for crops that increases production while
                positively impacting the environment at the same time.
              </p>
            </Stack>
          </Container>
          <Container className="key-features" maxW={1400}>
            <SimpleGrid columns={{ sm: 1, md: 3, lg: 3 }} spacing={6}>
              <Link to="/applications/improve-yield">
                <Flex className="key-features--container">
                  <StaticImage
                    className="key-features--icon"
                    placeholder="transparent"
                    src="../../images/crystal-green-icon1.svg"
                    alt="Ostara logo"
                  />
                  <p>
                    Improve
                    <br />
                    Yields
                  </p>
                </Flex>
              </Link>
              <Link to="/applications/soil-tie-up">
                <Flex className="key-features--container">
                  <StaticImage
                    className="key-features--icon"
                    placeholder="transparent"
                    src="../../images/crystal-green-icon3.svg"
                    alt="Ostara logo"
                  />
                  <p>
                    Reduce Soil <br />
                    Tie-Up
                  </p>
                </Flex>
              </Link>
              <Link to="/applications/nutrient-runoff">
                <Flex className="key-features--container">
                  <StaticImage
                    className="key-features--icon"
                    placeholder="transparent"
                    src="../../images/crystal-green-icon2.svg"
                    alt="Ostara logo"
                  />
                  <p>
                    Avoid Nutrient
                    <Box display="inline-block">Runoff and Leaching</Box>
                  </p>
                </Flex>
              </Link>
              {/* <Link to="/applications/turf-ornaments">
                <Flex className="key-features--container">
                  <StaticImage
                    className="key-features--icon"
                    placeholder="transparent"
                    src="../../images/crystal-green-icon4.svg"
                    alt="Ostara logo"
                  />
                  <p>
                    Enhance
                    <br />
                    Soil Health
                  </p>
                </Flex>
              </Link> */}
            </SimpleGrid>
          </Container>
        </Box>

        <Box w="100%" p={4} className="crystal-green-soil-type-container">
          <Container maxW={1400}>
            <h4>SOIL TYPES</h4>
            <h2>Putting the pH in Phosphate Uptake</h2>
            <div className="soil-type-chart">
              <Box className="chart-legends">
                <p className="legend1">Phosphate fixation by Iron (Fe)</p>
                <p className="legend2">Phosphate fixation due to Aluminum</p>
                <p className="legend3">Phosphate fixation by Calcium (Ca)</p>
              </Box>
              <Box className="chart">
                <StaticImage
                  placeholder="transparent"
                  src="../../images/soil-type.svg"
                  alt="Ostara logo"
                  width={700}
                />
              </Box>
            </div>
          </Container>
        </Box>
        {/* <Box w="100%" p={4} className="crystal-green-pearl-carousel-container">
          <Container maxW={1400}>
            <h4>THE DATA</h4>
            <h2>The Proof is in the Profit Potential</h2>
            <CrystalPearlCarousel />
            <h2>View More Crop Data</h2>
            <div className="crop-data-button-group">
              <Link to="/">CORN</Link>
              <Link to="/">SOYBEANS</Link>
              <Link to="/">HEAT</Link>
              <Link to="/">POTATO</Link>
              <Link to="/">CANOLA</Link>
              <Link to="/">ALFALFA</Link>
              <Link to="/">PEAS</Link>
              <Link to="/">SUGAR BEETS</Link>
            </div>
          </Container>
        </Box> */}

        {/* <Box w="100%" paddingY={4} className="crystal-research-container">
          <StaticImage
            className="crystal-research-detail-img"
            placeholder="transparent"
            src="../../images/crystal-green-img7.png"
            alt=""
          />
          <Box
            w={['100%', '100%', '60%', '53%']}
            className="crystal-research-detail-wrapper"
          >
            <Stack className="crystal-research-detail" maxW={626}>
              <h4>CASE STUDIES</h4>
              <Link to="/contact">
                Meet Zoe and see her tomato crop data after using Crystal Green
                last season.
              </Link>
            </Stack>
          </Box>
        </Box> */}

        <Container className="body-cta-container" maxW={1400}>
          <Box className="body-cta">
            <Link to="/contact">
              Contact us for more information or to order Crystal Green
            </Link>
          </Box>
        </Container>
      </main>
    </Layout>
  );
};

export default CrystalGreen;
